import React from 'react';
import { MdClose } from 'react-icons/md';

interface FileUploadPreviewProps {
  file: File;
  onRemove: (file: File) => void;
}

export const FileUploadPreview: React.FC<FileUploadPreviewProps> = ({
  file,
  onRemove,
}) => {
  const isImage = file.type.startsWith('image/');

  const formatFileSize = (size: number): string => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  };

  return (
    <div className='relative flex items-center gap-4 p-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm max-w-md select-none'>
      <button
        onClick={() => onRemove(file)}
        className='absolute top-1 right-1 p-1 bg-gray-200 rounded-full shadow-sm hover:bg-gray-300 transition'
        aria-label='Remove file'
      >
        <MdClose className='w-4 h-4 text-gray-600' />
      </button>

      {isImage ? (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className='w-12 h-12 object-cover rounded-md'
        />
      ) : (
        <div className='flex items-center justify-center w-12 h-12 bg-gray-300 rounded-md text-xs text-gray-700 font-semibold'>
          {file.name.split('.').pop()?.toUpperCase()}
        </div>
      )}

      {/* File Details */}
      <div className='flex flex-col'>
        <span className='font-medium text-gray-800 mr-8 text-sm break-words'>
          {file.name}
        </span>
        <span className='text-xs text-gray-500'>
          {formatFileSize(file.size)}
        </span>
      </div>
    </div>
  );
};
