import { useWebApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BigNumber } from 'bignumber.js';
import { DistributorMerkleTreeLeaf } from 'tokensoft-shared-types';

export const useGetMyDistributorMerkleLeaf = (
  distributorAddress: EvmAddress,
  enabled: boolean = true,
) => {
  const fetchWithClient = useWebApiClient();

  return useQuery<Maybe<DistributorMerkleTreeLeaf>>({
    queryKey: ['distributors', distributorAddress, 'merkle-tree-leaf'],
    queryFn: async () => {
      try {
        const leafRaw = await fetchWithClient(
          `distributors/${distributorAddress}/merkle-tree-leaf`,
        );

        if (!leafRaw) {
          return null;
        }

        const leaf: DistributorMerkleTreeLeaf = {
          amount: new BigNumber(leafRaw.amount),
          beneficiary: leafRaw.beneficiary,
          index: leafRaw.index,
          merkleProof: leafRaw.proof,
        };

        return leaf;
      } catch (e) {
        // 404 occurs if user doesn't have a merkle leaf on the distributor
        if (e instanceof AxiosError && e.response?.status === 404) {
          return null;
        }

        throw e;
      }
    },
    enabled: enabled,
  });
};
