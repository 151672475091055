import {
  useAddAccountDocument,
  useCreateAccountKycTier,
  useUpdateAccount,
  useUploadKycFileToS3,
} from '@apiServices';
import { ButtonRow, Error, PageHeader } from '@components';
import { useAccount, useAnalytics, useToast } from '@contexts';
import { TIER_ID } from '@enums';
import { KycDocumentUpload, LoaderButton } from '@newComponents';
import * as Sentry from '@sentry/react';
import { isEntity } from '@utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AdditionalInformationPanel } from '../additional-information-panel/additional-information-panel';
import { AccreditationHistoryPanel } from './accreditation-history-panel';
import { AccreditationQualificationsPanel } from './accreditation-qualifications-panel';

export const AccreditationForm = () => {
  const { showErrorToast } = useToast();
  useAnalytics('/account/accreditation-form');
  const { account } = useAccount();

  const {
    mutate: createAccountKycTier,
    isPending: createAccountKycTierLoading,
  } = useCreateAccountKycTier();

  const {
    mutate: updateAccount,
    isPending: updateAccountLoading,
    isSuccess,
  } = useUpdateAccount();

  const [qualificationMethod, setQualificationMethod] =
    useState<AccountAccreditationQualificationMethod>(
      isEntity(account) ? 'entity-net-worth' : 'net-worth',
    );
  const [notes, setNotes] = useState('');

  const [filesForUpload, setFilesForUpload] = useState<File[]>([]);

  const areDocumentsRequired = qualificationMethod !== 'none';

  const {
    mutateAsync: uploadKycFileToS3,
    isPending: isUploadingKycFiles,
    error: uploadKycFileError,
  } = useUploadKycFileToS3();

  const {
    mutateAsync: addAccountDocument,
    isPending: addAccountDocumentLoading,
    error: addAccountDocumentError,
  } = useAddAccountDocument();

  const isLoading =
    isUploadingKycFiles ||
    addAccountDocumentLoading ||
    createAccountKycTierLoading ||
    updateAccountLoading;

  const disableSubmit =
    isLoading || (areDocumentsRequired === true && filesForUpload.length === 0);

  const handleSubmit = async () => {
    try {
      const fileUploadResults = await Promise.all(
        filesForUpload.map((file) => uploadKycFileToS3(file)),
      );

      const documentIds = await Promise.all(
        fileUploadResults.map(async ({ fileUrl, s3Uri }, i) => {
          const file = filesForUpload[i];
          const { id } = await addAccountDocument({
            fileName: file.name,
            mimeType: file.type,
            s3Uri: s3Uri,
          });
          return id;
        }),
      );

      if (qualificationMethod === 'none') {
        await updateAccount({
          qualificationMethod: qualificationMethod,
        });
      } else {
        await createAccountKycTier({
          qualificationMethod: qualificationMethod,
          kycTierId: parseInt(TIER_ID.ACCREDITATION_KYC_TIER_ID),
          message: notes || 'Accreditation documentation submitted for review',
          documentIds: documentIds,
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      showErrorToast({
        description:
          'An error occurred while submitting your accreditation documents.',
      });
    }
  };

  return isSuccess ? (
    <Error
      title={"We're sorry!"}
      message={
        'You are not eligible for accreditation, but you may re-apply at any time.'
      }
    />
  ) : (
    <>
      <PageHeader title={'Accreditation (US)'} />

      <div data-test-id='profile' className='grid gap-6 grid-cols-1'>
        <AccreditationHistoryPanel
          account={account}
          qualificationMethod={qualificationMethod}
          onClick={setQualificationMethod}
        />
        <AccreditationQualificationsPanel
          account={account}
          qualificationMethod={qualificationMethod}
          onClick={setQualificationMethod}
        />
        {qualificationMethod === 'none' ? null : (
          <>
            <KycDocumentUpload
              files={filesForUpload}
              onFileChange={setFilesForUpload}
              qualificationMethod={qualificationMethod}
              type='accreditation'
            />
            <AdditionalInformationPanel
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </>
        )}

        <ButtonRow place={'between'}>
          <Link className='btn btn-outline-dark' to='/account'>
            Cancel
          </Link>
          <LoaderButton
            className=''
            isLoading={isLoading}
            disabled={disableSubmit}
            onClick={handleSubmit}
            label='Submit'
            loadingLabel='Uploading...'
          />
        </ButtonRow>
      </div>
    </>
  );
};
