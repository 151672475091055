import { useAccountApiClient } from '@apiClients';
import { useMutation } from '@tanstack/react-query';

export const useUploadKycFileToS3 = () => {
  const client = useAccountApiClient();
  return useMutation({
    mutationFn: async (
      file: File,
    ): Promise<{ fileUrl: HttpsUrl; s3Uri: S3Uri }> => {
      const { name: fileName, type: mimeType } = file;

      // format query params
      const queryParams = new URLSearchParams();
      queryParams.append('fileName', fileName);
      queryParams.append('mimeType', mimeType);

      // Get the presigned URL
      const { uploadUrl, fileUrl, s3Uri } = await client(
        `accounts/kyc/presigned-upload-url?${queryParams.toString()}`,
      );

      // Upload the file directly to S3 using the presigned URL
      await fetch(uploadUrl, {
        method: 'put',
        body: file,
      });

      return { fileUrl, s3Uri };
    },
  });
};
