import { useAccountApiClient } from '@apiClients';
import { useMutation } from '@tanstack/react-query';

export const useAddAccountDocument = () => {
  const fetchWithClient = useAccountApiClient();

  return useMutation({
    mutationFn: async (data: {
      s3Uri: S3Uri;
      fileName: string;
      mimeType: string;
    }) => {
      const response = await fetchWithClient(`accounts/documents`, {
        method: 'PUT',
        data: data,
      });
      return response;
    },
  });
};
