import { Button } from '@components';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

type ButtonProps = React.ComponentProps<typeof Button>;

interface LoaderButtonProps extends ButtonProps {
  isLoading?: boolean;
  label?: string;
  loadingLabel?: string;
}

export const LoaderButton: React.FC<LoaderButtonProps> = ({
  isLoading = false,
  label = 'Save',
  loadingLabel = 'Saving...',
  className = '',
  ...restProps
}) => {
  return (
    <Button className={className} {...restProps}>
      {isLoading ? (
        <div className='flex items-center gap-2'>
          <AiOutlineLoading3Quarters size={24} className='animate-spin' />
          {loadingLabel}
        </div>
      ) : (
        label
      )}
    </Button>
  );
};
