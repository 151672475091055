import { Card } from '@components';
import { FileUpload } from '@newComponents';
import { renderDocUploadText } from './kyc-document-upload-text';

interface KycDocumentUploadProps {
  type: KycDocumentUploadType;
  qualificationMethod: AccountAccreditationQualificationMethod;
  entityType?: AccountEntityType;
  onFileChange: (files: File[]) => void;
  files: File[];
}

export const KycDocumentUpload: React.FC<KycDocumentUploadProps> = ({
  type,
  qualificationMethod,
  entityType,
  onFileChange,
  files,
}) => {
  const displayType = type.charAt(0).toUpperCase() + type.slice(1);
  return (
    <Card title={`${displayType || 'Upload'} Documents`}>
      {renderDocUploadText(type, qualificationMethod, entityType)}
      <FileUpload
        onFileChange={onFileChange}
        files={files}
        uploadStyle='dropzone'
      />
      <p className='text-xs text-gray-500 pt-2'>
        * By providing information, you represent and warrant that it is true,
        complete, and correct.
      </p>
    </Card>
  );
};
