import { Account } from '@customTypes';
import { isEntity } from '@utils';

import { Card, RadioInput } from '@components';

export const AccreditationQualificationsPanel = ({
  account,
  qualificationMethod,
  onClick,
}: {
  account: Account;
  qualificationMethod: AccountAccreditationQualificationMethod;
  onClick: (method: AccountAccreditationQualificationMethod) => void;
}) => {
  return qualificationMethod === 'letter' ? null : (
    <Card title='Accreditation Qualifications'>
      <p className='pb-4'>
        Please select how you would like to qualify as an accredited investor:
      </p>
      <div className='pb-4'>
        {isEntity(account) ? (
          <>
            <RadioInput
              name='accreditationQualification'
              className='flex'
              checked={qualificationMethod === 'entity-net-worth'}
              onClick={() => onClick('entity-net-worth')}
            >
              <div className='pt-1 leading-tight'>
                Entity with total assets or investments in excess of $5,000,000
                and have not been formed for the purpose of acquiring token
                interests.
              </div>
            </RadioInput>

            <RadioInput
              name='accreditationQualification'
              className='flex'
              checked={qualificationMethod === 'trust-net-worth'}
              onClick={() => onClick('trust-net-worth')}
            >
              <div className='pt-1 leading-tight'>
                Trust (other than a business trust) with total assets in excess
                of $5,000,000 which was not formed for the purpose of acquiring
                token interests and whose decision to invest has been directed
                by a person who has such knowledge and experience in financial
                and business matters that such person is capable of evaluating
                the merits and risks of investment.
              </div>
            </RadioInput>

            <RadioInput
              name='accreditationQualification'
              className='flex'
              checked={qualificationMethod === 'family-office'}
              onClick={() => onClick('family-office')}
            >
              <div className='pt-1 leading-tight'>
                Family office (as defined in Rule 202(a)(11)(G)–1 under the
                Advisers Act) and you were not formed for the specific purpose
                of acquiring the securities offered, (1) you own investments in
                excess of $5 million, and (2) your investment is directed by a
                person with such knowledge and experience in financial and
                business matters that such family office is capable of
                evaluating the merits and risks of the prospective investment (a
                “Qualifying Family Office”).
              </div>
            </RadioInput>

            <RadioInput
              name='accreditationQualification'
              className='flex'
              checked={qualificationMethod === 'family-client'}
              onClick={() => onClick('family-client')}
            >
              <div className='pt-1 leading-tight'>
                Family client whose investments are directed by a Qualifying
                Family Office.
              </div>
            </RadioInput>

            <RadioInput
              name='accreditationQualification'
              className='flex'
              checked={qualificationMethod === 'accredited-owners'}
              onClick={() => onClick('accredited-owners')}
            >
              <div className='pt-1 leading-tight'>
                Each of your equity owners is an accredited investor.
              </div>
            </RadioInput>
          </>
        ) : (
          <>
            <RadioInput
              name='accreditationQualification'
              className='flex'
              checked={qualificationMethod === 'net-worth'}
              onClick={() => onClick('net-worth')}
            >
              <div className='pt-1 leading-tight'>
                Net worth over $1 million excluding primary residence
                (individually or with spouse/partner)
              </div>
            </RadioInput>

            <RadioInput
              name='accreditationQualification'
              className='flex'
              checked={qualificationMethod === 'income'}
              onClick={() => onClick('income')}
            >
              <div className='pt-1 leading-tight'>
                Income over $200,000 (individually) or $300,000 (with
                spouse/partner) in each of prior two years, and reasonably
                expect the same for current year
              </div>
            </RadioInput>

            <RadioInput
              name='accreditationQualification'
              className='flex'
              checked={qualificationMethod === 'professional'}
              onClick={() => onClick('professional')}
            >
              <div className='pt-1 leading-tight'>
                Investment professional in good standing holding Series 7,
                Series 65 and/or Series 82 license
              </div>
            </RadioInput>
          </>
        )}

        <RadioInput
          name='accreditationQualification'
          className='flex'
          checked={qualificationMethod === 'none'}
          onClick={() => onClick('none')}
        >
          <div className='pt-1 leading-tight'>None of the above</div>
        </RadioInput>
      </div>
    </Card>
  );
};
