import {
  useGetChain,
  useGetDistributorByContractAddress,
  useGetMyDistributorClaimableAmount,
  useGetMyDistributorMerkleLeaf,
  useGetTokenDetails,
} from '@apiServices';
import { Button, LoadingIndicator, Text } from '@components';
import { useToast } from '@contexts';
import { Card, ChainGate, ConfettiMessage } from '@newComponents';
import {
  div,
  eq,
  getBlockExplorerTransactionUrl,
  getTruncatedEvmAddress,
} from '@utils';
import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import { EventModal } from '../eligibility/EventModal';
import { EventPropertyRow } from '../event-property-row';
import { ClaimFlow } from './claim-flow';

type ClaimCardProps = {
  className?: string;
  distributorAddress: EvmAddress;
  chainId: ChainId;
  isClaimAvailable: boolean;
};

export const ClaimCard = ({
  className = '',
  distributorAddress,
  chainId,
  isClaimAvailable,
}: ClaimCardProps) => {
  const [showingSuccessModal, setShowingSuccessModal] = useState(false);
  const [transactionHash, setTransactionHash] =
    useState<Maybe<EvmAddress>>(null);
  const { showErrorToast } = useToast();

  const {
    data: distributor,
    isPending: isDistributorPending,
    isError: isDistributorError,
    error: distributorError,
  } = useGetDistributorByContractAddress(distributorAddress);

  const {
    data: chain,
    isPending: isChainPending,
    isError: isChainError,
    error: chainError,
  } = useGetChain(chainId);

  const {
    data: merkleLeaf,
    isPending: isMerkleLeafPending,
    isError: isMerkleLeafError,
  } = useGetMyDistributorMerkleLeaf(distributorAddress);

  const {
    data: token,
    isPending: isTokenPending,
    isError: isTokenError,
    error,
  } = useGetTokenDetails(chainId, distributor?.token.address ?? null);

  const {
    data: claimableAmount,
    isPending: isClaimablePending,
    isError: isClaimableError,
    error: claimableError,
  } = useGetMyDistributorClaimableAmount(distributorAddress);

  const [claimModalOpen, setClaimModalOpen] = useState(false);
  const openClaimModal = () => {
    setClaimModalOpen(true);
  };

  const closeClaimModal = () => {
    setClaimModalOpen(false);
  };

  if (
    isDistributorPending ||
    isChainPending ||
    isMerkleLeafPending ||
    isTokenPending ||
    isClaimablePending
  ) {
    return <LoadingIndicator />;
  }

  if (isDistributorError) return <div>Error: {distributorError.message}</div>;

  if (isChainError) return <div>Error: {chainError.message}</div>;

  const truncatedTokenAddress = getTruncatedEvmAddress(
    distributor?.token?.address ?? '',
  );

  const calculateAllocation = (allocation: BigNumber, decimals: number) => {
    return div(allocation, Math.pow(10, decimals));
  };

  const handleClaimSuccess = (receipt: any) => {
    closeClaimModal();
    setTransactionHash(receipt.transactionHash);
    setShowingSuccessModal(true);
  };

  const handleChainChanged = (chainId: number, isRequiredChain: boolean) => {
    if (!isRequiredChain) {
      setClaimModalOpen(false);
    }
  };

  return (
    <Card className={className} title='Distribution Details'>
      <div className='flex flex-col gap-2'>
        <EventPropertyRow
          label='Token Allocation'
          value={calculateAllocation(
            distributor.totalAllocation,
            distributor.token.decimals,
          )}
        />
        <EventPropertyRow
          label='Token Address'
          value={
            <div className='flex gap-2 font-medium items-center cursor-pointer'>
              <VscLinkExternal />
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={getBlockExplorerTransactionUrl(
                  chain,
                  distributor.token.address,
                )}
              >
                {truncatedTokenAddress}
              </a>
            </div>
          }
        />
        {token && <EventPropertyRow label='Token Name' value={token.name} />}
      </div>
      <ChainGate
        requiredChainId={distributor.chainId}
        onChainChanged={handleChainChanged}
      >
        {/* Add message if claimable amount is 0 */}
        {claimableAmount !== null && eq(claimableAmount, 0) && (
          <p className='bg-yellow-50 rounded-md p-2 m-4 text-center text-gray-500 mt-2'>
            You currently have no tokens available to claim.
          </p>
        )}
        {isClaimAvailable && !!merkleLeaf && (
          <div className='flex justify-center mt-4'>
            <Button
              onClick={openClaimModal}
              disabled={
                token === null ||
                claimableAmount === null ||
                eq(claimableAmount, 0) ||
                transactionHash !== null
              }
            >
              Claim
            </Button>
          </div>
        )}
        {isClaimAvailable && !isMerkleLeafPending && merkleLeaf === null && (
          <Text className='bg-yellow-50 rounded-md p-4 text-center text-gray-500 mt-2'>
            The connected wallet has no claims on this distributor.
          </Text>
        )}
      </ChainGate>
      <EventModal
        isOpen={claimModalOpen}
        onRequestClose={closeClaimModal}
        contentLabel='Claim Modal'
        shouldCloseOnOverlayClick={false}
      >
        <ClaimFlow
          chainId={chainId}
          distributorAddress={distributorAddress}
          merkleLeaf={merkleLeaf!}
          token={token!}
          claimAmount={claimableAmount ?? new BigNumber(0)}
          onClaimSuccess={handleClaimSuccess}
        />
      </EventModal>
      <EventModal
        isOpen={showingSuccessModal}
        onRequestClose={() => setShowingSuccessModal(false)}
        contentLabel='Claim Success'
      >
        <ConfettiMessage
          message={
            <div>
              You have successfully claimed your tokens! Click the link below to
              view your transaction.
              <div className='my-3 flex items-center justify-center gap-2'>
                {transactionHash !== null && (
                  <>
                    <VscLinkExternal />
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={getBlockExplorerTransactionUrl(
                        chain,
                        transactionHash,
                      )}
                    >
                      {getTruncatedEvmAddress(transactionHash)}
                    </a>
                  </>
                )}
              </div>
            </div>
          }
          onFinished={() => setShowingSuccessModal(false)}
        />
      </EventModal>
    </Card>
  );
};
