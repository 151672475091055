import { useAuth } from '@contexts';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { useCallback } from 'react';

interface RequestConfig extends AxiosRequestConfig {
  token?: string;
  signOut?: Function;
  shouldSignOutOnForbidden?: boolean;
}

const accountApiClient = (
  endpoint: string,
  {
    data,
    token,
    signOut,
    headers: customHeaders,
    shouldSignOutOnForbidden = true,
    ...customConfig
  }: RequestConfig,
) => {
  let method: Method = data ? 'post' : 'get';

  const config: AxiosRequestConfig<any> = {
    method,
    url: `${import.meta.env.VITE_API_URL}/account/v1/${endpoint}`,
    data: data || undefined,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(data ? { 'Content-Type': 'application/json' } : {}),
      ...customHeaders,
    },
    ...customConfig,
  };

  return axios.request(config).then(
    (res) => res.data,
    (error) => {
      const { status: statusCode } = error.toJSON();
      if (statusCode === 403 && shouldSignOutOnForbidden) {
        if (signOut) {
          signOut();
        }
      } else if (statusCode === 500) {
        return Promise.reject({
          message: error.response.data.message,
        });
      }

      return Promise.reject(error.response?.data || error);
    },
  );
};

const useAccountApiClient = () => {
  const {
    user: { token },
    disconnect: signOut,
  } = useAuth();
  return useCallback(
    (endpoint: string, config: Omit<RequestConfig, 'token' | 'signOut'> = {}) =>
      accountApiClient(endpoint, { ...config, token, signOut }),
    [token],
  );
};

export { accountApiClient, useAccountApiClient };
